/* eslint-disable */

<template>
  <div>
    <div class="bg-dark main-border rounded-lg overflow-hidden" v-if="!quizCompleted">
      <div class="flex justify-between items-center bg-light main-bottom-border rounded-t-lg p-4">
        <h2>{{ dataLocal.simData.simulation.name }} Quiz</h2>

        <div class="flex items-center bg-dark main-border p-3 px-4 rounded-lg">
          <h4>Question {{ currentQuestion + 1 }}/{{ quizData.questions }}</h4>
        </div>
      </div>

      <div class="" v-for="(question, questionIndex) in shuffledQuestions" v-if="currentQuestion === questionIndex" :key="questionIndex">
        <h4 class="p-5 font-light main-bottom-border" style="line-height: 2rem">{{ question.question }}</h4>

        <div class="grid grid-cols-4 gap-x-4 p-5">
          <div
            class="answerChoice flex items-center gap-x-3 p-3 pr-4 rounded-lg main-border bg-light cursor-pointer"
            :class="{
              'border-primary': questionAnswers[questionIndex] === choiceIndex && questionCorrect === 'default',
              'border-success': questionAnswers[questionIndex] === choiceIndex && questionCorrect === 'true',
              'border-danger': questionAnswers[questionIndex] === choiceIndex && questionCorrect === 'false',
            }"
            v-for="(choice, choiceIndex) in question.choices"
            :id="choiceIndex"
            @click="onPreviousQuestion ? null : selectAnswer(questionIndex, choiceIndex)"
          >
            <span
              class="flex items-center p-2 rounded-full main-border bg-light answerCircle"
              :class="{ selected: questionAnswers[questionIndex] === choiceIndex }"
            >
              <font-awesome-icon v-if="choiceIndex === 0" icon="fa-solid fa-a" class="h-5 w-5" />
              <font-awesome-icon v-if="choiceIndex === 1" icon="fa-solid fa-b" class="h-5 w-5" />
              <font-awesome-icon v-if="choiceIndex === 2" icon="fa-solid fa-c" class="h-5 w-5" />
              <font-awesome-icon v-if="choiceIndex === 3" icon="fa-solid fa-d" class="h-5 w-5" />
            </span>

            <div class="flex flex-col">
              <p>{{ choice.text }}</p>
              <span class="answerPill">{{ choice.abrv }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end bg-light main-top-border p-5">
        <div class="flex w-full">
          <div class="flex-start w-full items-center gap-x-2 answerMessage" :class="{ active: questionCorrect === 'true' }">
            <font-awesome-icon icon="fa-regular fa-circle-check" class="h-6 w-6 text-success" />
            <h5 class="text-success">That answer is correct!</h5>
          </div>
          <div class="flex-start w-full items-center gap-x-2 answerMessage" :class="{ active: questionCorrect === 'false' }">
            <font-awesome-icon icon="fa-regular fa-circle-x" class="h-6 w-6 text-danger" />
            <h5 class="text-danger">That answer is not correct!</h5>
          </div>
          <div class="flex gap-x-4 justify-end w-full">
            <vs-button
              v-if="currentQuestion !== 0"
              class="w-fit font-bold"
              icon="icon-arrow-left"
              icon-pack="feather"
              color="danger"
              type="gradient"
              @click="previousQuestion"
            >
              Previous Question
            </vs-button>

            <vs-button
              v-if="questionCorrect !== 'true' && !onPreviousQuestion && currentQuestion !== dataLocal.quizData.questions - 1"
              class="w-fit font-bold"
              icon="icon-check-circle"
              icon-pack="feather"
              color="success"
              type="gradient"
              icon-after
              @click="submitQuestion"
              :disabled="questionAnswers[currentQuestion] === undefined || questionAnswers[currentQuestion] === null"
            >
              Submit Question
            </vs-button>

            <vs-button
              v-if="questionCorrect === 'true' && currentQuestion !== dataLocal.quizData.questions - 1"
              class="w-fit font-bold"
              icon="icon-arrow-right"
              icon-pack="feather"
              color="primary"
              type="gradient"
              icon-after
              @click="nextQuestion"
              :disabled="questionAnswers[currentQuestion] === undefined || questionAnswers[currentQuestion] === null"
            >
              Next Question
            </vs-button>

            <vs-button
              v-if="questionCorrect !== 'true' && onPreviousQuestion && currentQuestion !== dataLocal.quizData.questions - 1"
              class="w-fit font-bold"
              icon="icon-arrow-right"
              icon-pack="feather"
              color="primary"
              type="gradient"
              icon-after
              @click="nextQuestion"
              :disabled="questionAnswers[currentQuestion] === undefined || questionAnswers[currentQuestion] === null"
            >
              Next Question
            </vs-button>

            <vs-button
              v-if="currentQuestion == dataLocal.quizData.questions - 1"
              class="w-fit font-bold"
              icon="icon-check-circle"
              icon-pack="feather"
              color="success"
              type="gradient"
              icon-after
              @click="submitQuiz"
              :disabled="questionAnswers[currentQuestion] === undefined || questionAnswers[currentQuestion] === null"
            >
              Submit Quiz
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-dark main-border rounded-lg overflow-hidden" v-if="quizCompleted">
      <div class="flex justify-between items-center rounded-t-lg p-4">
        <div class="flex items-center gap-x-3">
          <font-awesome-icon icon="fa-regular fa-circle-check" class="h-10 w-10 text-success" />
          <h2>{{ dataLocal.simData.simulation.name }} Quiz</h2>
        </div>

        <div
          class="flex items-center bg-dark main-border border-success p-3 px-4 rounded-lg"
          style="background-color: rgb(49, 185, 82, 0.2)"
        >
          <h4>Completed</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      quizData: JSON.parse(JSON.stringify(this.data.quizData)),

      currentQuestion: 0,
      correctQuestions: 0,
      questionCorrect: 'default',
      questionAnswers: [],

      onPreviousQuestion: false,
      quizCompleted: false,
    };
  },
  methods: {
    selectAnswer(questionIndex, answerIndex) {
      if (this.questionAnswers[questionIndex] !== undefined) {
        this.$set(this.questionAnswers, questionIndex, answerIndex);
        this.questionCorrect = 'default';
      } else {
        this.questionAnswers.push(answerIndex);
        this.questionCorrect = 'default';
      }
    },
    submitQuestion() {
      if (this.questionAnswers[this.currentQuestion] === this.shuffledQuestions[this.currentQuestion].answer) {
        this.questionCorrect = 'true';
      } else {
        this.questionCorrect = 'false';
      }
    },
    previousQuestion() {
      this.currentQuestion--;
      this.questionCorrect = 'default';
      this.onPreviousQuestion = true;
    },
    nextQuestion() {
      this.currentQuestion++;
      this.questionCorrect = 'default';
      this.correctQuestions++;

      if (this.questionAnswers[this.currentQuestion] !== undefined) {
        this.onPreviousQuestion = true;
      } else {
        this.onPreviousQuestion = false;
      }
    },
    submitQuiz() {
      if (this.questionAnswers[this.currentQuestion] === this.shuffledQuestions[this.currentQuestion].answer) {
        this.questionCorrect = 'true';
        this.quizCompleted = true;

        return this.$emit('simulation_quiz_correct');
      } else {
        this.questionCorrect = 'false';
      }
    },
  },
  components: {
    Prism,
    vSelect,
    shuffle,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    shuffledQuestions() {
      return shuffle(this.quizData.questionData);
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.answerPill {
  background-color: rgb(0, 85, 255, 0.3);
  padding: 0rem 0.7rem;
  border-radius: 0.6rem;
  font-size: 12px;
  border: 1px solid #0053ff;
  width: fit-content;
}

.answerChoice {
  border: 1px solid #181d2a;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid #3f5271;
  }
}

.answerCircle {
  transition: all 0.2s ease-in-out;
}
.selected {
  border: 1px solid #3f5271 !important;
}

.answerMessage {
  display: none;
  transition: all 0.2s ease-in-out;
}

.active {
  display: flex;
}
</style>
